.mapContainer {
  width: 100%;
  height: 80vh;
}

:global(.gmnoprint[role='menubar']:not(:last-of-type)) {
  margin: 35px 0 0 0 !important;
  left: 334px;
  display: flex;

  @media (max-width: 700px) {
    margin: 54px 0 0 0 !important;
  }

  div {
    button {
      width: 35px !important;
      height: 35px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
}
