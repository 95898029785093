$colorPrimary: #005eb8;
$mainColorBlack: #000929;
$mainColorBlack70: rgba(0, 9, 41, 0.7);
$blueShades92: #dee8f7;
$blueShades98: #f7fbfd;
$blueShades96: #eff4fb;
$mainDark50: rgba(0, 9, 41, 0.5);
$gray: #394150;
$black: #000929;
$white: #ffffff;
$soft: #f9fafb;
