@import "../../styles/variables.scss";

.select {
    width: 100%;
    text-align: left;
    height: 48px;
}

.select_list {
    padding: 0;
    :global(.ant-select-item-option) {
        height: 44px;
        align-items: center;
        border-radius: 0;

        &:global(.ant-select-item-option-selected) {
            color: $colorPrimary;
        }
    }
}

.label {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    margin-bottom: 5px;
    text-align: left;
    display: block;
    color: #000;
}
