.container {
  display: flex;
  margin-top: 35px;
  button {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 700px) {
    margin-top: 54px;
  }
}
.deleteButton {
  height: 31px;
  width: 28px;
}

.deleteIcon {
  font-size: 20px;
  padding: 2px;
}
