@import '../../styles/variables';

.wrapper {
  width: 100%;
}
.link {
  display: flex;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $blueShades92;
  padding: 20px 48px;
  color: $gray;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  gap: 20px;
  @media (max-width: 1024px) {
    display: none;
  }
}

.outer {
  display: flex;
  gap: 20%;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.row {
  width: 1000px;
  margin-right: 20%;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.titlePage {
  font-size: 24px;
  font-weight: 700;
  padding: 24px;
  @media (max-width: 1024px) {
    background-color: $white;
  }
}

.nav {
  padding: 24px 48px;
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $blueShades92;
    padding: 0px;
    background-color: $white;
  }
}

.tab {
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid $blueShades92;
  cursor: pointer;
  @media (max-width: 1024px) {
    border-bottom: 0px solid $blueShades92;
    padding: 8px 24px;
  }
}

.activeTab {
  padding: 16px 24px;
  font-size: 16px;
  min-width: 150px;
  color: $black;
  font-weight: 700;
  border-left: 3px solid $colorPrimary;
  border-bottom: 1px solid $blueShades92;
  cursor: pointer;

  @media (max-width: 1024px) {
    border-left: 0px solid $colorPrimary;
    border-bottom: 3px solid $colorPrimary;
    padding: 8px 24px;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1024px) {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
  }
}

.formWrapper {
  width: 1000px;
  margin-right: 20%;
  padding-top: 24px;
  @media (max-width: 1024px) {
    margin-right: 0;
    padding: 24px;
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $blueShades92;
}

.titleForm {
  padding: 24px 24px 0 24px;
  color: $black;
  font-size: 20px;
  font-weight: 700;
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid $blueShades92;
  @media (max-width: 1024px) {
    padding: 0 24px 24px 24px;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 132px 24px;
  border-bottom: 1px solid $blueShades92;
}

.innerAccount {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid $blueShades92;
}

.inputsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.label {
  margin-bottom: 8px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.input {
  height: 48px;
  width: 100% !important;
  background-color: $white !important;
}

.helpText {
  font-size: 14px;
}
.helpLink {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 700;
}

.button {
  margin: 24px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.buttonAccount {
  margin: 180px 24px 24px 24px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.phone {
  input {
    width: 580px;
  }
}
