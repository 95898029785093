@import '../../../styles/variables';
.formWrapper {
  width: 1000px;
  margin-right: 20%;
  padding-top: 24px;
  @media (max-width: 1024px) {
    margin-right: 0;
    padding: 24px;
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $blueShades92;
}

.titleForm {
  padding: 24px 24px 0 24px;
  color: $black;
  font-size: 20px;
  font-weight: 700;
}

.textWrapper {
  font-size: 14px;
}

.textGray {
  color: $gray;
  opacity: 0.6;

  @media (max-width: 1024px) {
    padding-bottom: 16px;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;

  padding: 24px 24px 0 24px;
}

.innerMain {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid $blueShades92;

  @media (max-width: 1024px) {
    padding: 0;
    margin: 24px;
    gap: 0;
  }
}
.inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-bottom: 1px solid $blueShades92;
}

.label {
  margin-bottom: 8px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
}

.input {
  height: 48px;
  width: 100% !important;
  background-color: $white !important;
}

.deleteTitle {
  font-weight: 700;
}

.button {
  margin: 180px 24px 24px 24px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
