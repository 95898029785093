.galleryContainer {
  position: relative;
}

.viewPhotos {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    bottom: 40%;
  }
}

.gridContainer {
  max-height: 480px;
}

.imageWrapper {
  display: flex;
  height: 480px;

  @media (max-width: 992px) {
    height: 240px;
  }
}

.image {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.secondaryImageWrapper {
  height: 230px;
  display: flex;

  @media (max-width: 992px) {
    margin-top: 12px;
    height: 100%;
  }
}
