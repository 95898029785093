.layout {
  overflow-x: clip;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: #f5f5f5; /* Update to the desired background color */
  padding: 0; /* Remove padding */

  &.mobile {
    background-color: #f5f5f5; /* Ensure the background color is consistent for mobile */
    padding: 0!important; /* Remove padding */
  }
}
