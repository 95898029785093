@import '../../styles/variables';

.container {
  background-color: #FFF;
  height: 100vh;
  overflow-y: scroll;
}

.content {
  width: 1200px;
  margin: auto;
  padding: 20px;

  @media (max-width: 992px) {
    width: auto;
  }
}

.termsCard {
  margin-bottom: 20px;
}

.termsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.termsText {
  margin-bottom: 8px;
}

.titleContainer {
  margin-bottom: 15px;
  @media (max-width: 992px) {
    margin-bottom: 24px;
  }
}

.investContainer {
  width: 376px;
  border-radius: 8px;
  border: 1px solid #DEE8F7;
  padding: 20px;
}

.navigation {
  margin-bottom: 20px;
  button {
    @media (max-width: 992px) {
      font-size: 18px;
      font-weight: 700;
      color: $colorPrimary !important;
    }
  }
}

.listingName {
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.01em;

  @media (max-width: 992px) {
    padding-bottom: 8px;
  }
}

.listingAddress {
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.005em;
  opacity: 0.5;
  @media (max-width: 992px) {
    padding-bottom: 24px;
  }
}

.cardContainer {
  margin-top: 20px;
}

.infoBlock {
  width: 100%;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.infoTitle {
  opacity: .7;
  min-width: 90px;
}

.infoValue {
  font-weight: 600;
}

.investWrapper {
  padding: 12px 12px 20px;
  border-radius: 8px;
  border: 1px solid #dee8f7;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.investTitle {
  font-size: 14px;
  font-weight: 500;
  color: $black;
  opacity: 0.5;
}

.investPrice {
  color: $colorPrimary;
  font-size: 24px;
  font-weight: 800;
}

.investBtn {
  span {
    svg {
      g {
        opacity: 1;
        path {
          stroke: $white;
        }
      }
    }
  }
}

.listingPositionMarker {
  background-color: #005EB8;
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 14px;
  padding: 7px;
  position: relative;
}

.listingPositionMarker::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #005EB8;
}

.mapListingsButton {
  padding: 15px 0;
  text-align: center;

    @media (max-width: 992px) {
    font-weight: 700;
    color: $colorPrimary !important;
  }
}

.mapContainer {
  height: 350px;
}

.listPrice {
  opacity: .5
}

.listPriceValue {
  color: $colorPrimary;
  font-size: 24px;
  text-align: left;
  display: block;
  margin: 10px 0;
  font-weight: 800;
}

.lendButton {
  font-size: 16px;
  text-align: center;
  width: 100%;
  min-height: 56px;
}

.lendIcon  {
  g {
    opacity: 1;
  }
  svg path {
    stroke: $white;
  }
}


.text {
  color: #6c727f;
  font-size: 14px;
  font-weight: 400;
}

.bottom {
  margin-top: 300px;
}

.bottomInvest {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomInvestPrice {
  color: $colorPrimary;
  font-size: 20px;
  font-weight: 800;
}
.bottomBtn {
  width: auto !important;
  padding: 0 24px !important;
}
