@import '../../styles/variables';

.container {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: relative;
}

.mls {
  color: #6c727f;
}

.searchTable {
  min-height: 688px;
}

.searchContainer {
  margin-bottom: 15px;
}

.ant-table {
  background-color: white!important;
}

.selectsWrapper {
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}

.input {
  margin-right: 8px;
  margin-bottom: 8px;
}

.segmentedContainer {
  border: 1px solid #d3d3d3; /* Light grey border */
  padding: 3px; /* Optional padding */
  border-radius: 5px; /* Optional border radius for rounded corners */
}


.searchIcon {
  padding-top: 2px;
}

.filters {
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}

.filtersButton {
  background-color: $colorPrimary;
  color: #ffffff;
  height: 40px;
}

.tableOptions {
  margin: 0 50%;
  display: flex;
  gap: 10px;
}

.row {
  &:hover {
    cursor: pointer;
  }
}

.save {
  width: 156px;
  height: 48px;
}

:global(.ant-select-selector) {
  height: 48px !important;
}

.header {
  @media (max-width: 1024px) {
    padding-bottom: 16px;
  }
}

.grayNav {
  @media (max-width: 1024px) {
    color: #6c727f;
  }
}

.blackNav {
  @media (max-width: 1024px) {
    color: $black;
    font-size: 14px;
  }
}

.icon {
  @media (max-width: 1024px) {
    margin: 0 4px;
  }
}

.btnFilters {
  @media (max-width: 1024px) {
    border: 1px solid $blueShades92 !important;
    padding: 0 8px !important;
    height: 40px !important;
  }
}

.inputSearch {
  @media (max-width: 1024px) {
    :global(.ant-input-prefix) {
      margin-right: 16px;
    }

    :global(.ant-input-affix-wrapper) {
      padding: 12px 16px !important;
      border: 2px solid $blueShades92 !important;
      background: $blueShades98 !important;

      input {
        font-size: 16px !important;
        color: $mainDark50 !important;
      }
    }

    :global(.anticon) {
      svg path {
        stroke: $colorPrimary !important;
      }
    }
  }
}

.inputWrapper {
  @media (max-width: 1024px) {
    padding: 16px 0;
  }
}
.inputNumber {
  @media (max-width: 1024px) {
    border: 2px solid $blueShades92 !important;
    width: 100px;
  }
}

.btnWrapper {
  @media (max-width: 1024px) {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 24px auto;
  }
}

:global(.ant-select-single.ant-select-lg) {
  @media (max-width: 1024px) {
    height: 48px;
  }
}

.btn {
  @media (max-width: 1024px) {
    background-color: $black !important;
    border: 2px solid $black !important;
    display: flex !important;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }
}

:global(.ant-pagination-prev) {
  @media (max-width: 1024px) {
    border-radius: 50% !important;
    border: 1px solid $blueShades92;
  }
}

:global(.ant-pagination-next) {
  @media (max-width: 1024px) {
    border-radius: 50% !important;
    border: 1px solid $blueShades92;
  }
}

:global(.ant-pagination .ant-pagination-item) {
  @media (max-width: 1024px) {
    border: none !important;
    height: auto !important;
    margin-inline-end: 0 !important;
  }
}

:global(.ant-pagination .ant-pagination-item-active a) {
  @media (max-width: 1024px) {
    color: $black !important;
  }
}

:global(.ant-table-wrapper) {
  @media (max-width: 992px) {
    overflow-x: auto;
  }
}

// :global(.ant-table-cell) {
//   @media (max-width: 992px) {
//     padding: 40px 50px 40px 24px !important;
//   }
// }

:global(.ant-table-wrapper .ant-table-pagination-right) {
  @media (max-width: 1024px) {
    justify-content: space-between;
    align-items: center;
  }
}
:global(.ant-drawer) {
  position: fixed !important;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
  gap: 20px; // Add consistent spacing between elements
  
  @media (max-width: 1024px) {
    padding: 10px 0;
    flex-direction: column; // Stack vertically on mobile
    width: 100%;
    gap: 12px; // Slightly reduced gap for mobile
  }

  button {
    width: 170px !important;
    height: 48px;
    
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
}

.actions {
  @media (max-width: 1024px) {
    width: 100% !important;
  }
  
  // Add specific spacing for the dropdown container
  :global(.ant-dropdown-trigger) {
    width: 170px !important;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
}
