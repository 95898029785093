.container {
  margin-top: 50px;
  overflow-y: auto;
  max-height: 980px;
}

.modalTitle {
  padding-top: 20px;
}

.searchSection {
  padding-top: 20px;
}

.searchBlock {
  width: 100%;
}

.searchItem {
  margin-bottom: 20px;
}
.searchTitle {
  padding-bottom: 10px;
}

.searchOption {
  min-height: 48px;
}

.break {
  width: 100%;
}

.save {
  width: 100%;
}

