@import '../../../../styles/variables';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: $soft;
  color: $black;
  z-index: 10;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: $white;
  border-bottom: 1px solid $blueShades92;
}

.logo {
  font-size: 24px;
  font-weight: 700;
}

.iconX {
  svg {
    width: 24px;
    height: 24px;
  }
}
.content {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  justify-content: space-between;
}

.tab{
    padding-left: 16px;
    align-items: center;
}
.active{
    padding-left: 16px;
    background-color: $blueShades92;
    color: $colorPrimary;
    border-radius: 8px;
    font-weight: bold;
    align-items: center;

    span{
        svg{
            fill:$colorPrimary;
             path {
                stroke: $colorPrimary;
              }
        }
    }
}