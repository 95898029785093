.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  padding-left: 48px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 8px;
  line-height: 25px;
  height: 48px;
  width: 100%;
}

.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
}
