.container {
  height: 144px;
  border-radius: 8px;
  border: 1.5px solid #eff4fb;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 24px;

  @media (max-width: 992px) {
    height: auto;
  }
}

.titleContainer {
  margin-bottom: 20px;
}

.title {
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
}

.owner {
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 10px;
}

.contact {
  font-weight: 400;
  line-height: 19.6px;
  opacity: 0.5;
}

.btn {
  @media (max-width: 992px) {
    height: 48px;
  }
}
