.header {
  margin-bottom: 20px;
}

.title {
  font-weight: 700;
  line-height: 37.5px;
  letter-spacing: -0.01em;
}

.row {
  &:hover {
    cursor: pointer
  }
}
