.menuItem {
  padding-top: 5px;
}

.settingsMenuContainer {
  position: absolute;
  bottom: 40px;
  align-items: center;
  width: 100%;
}

.logo {
  cursor: pointer;
}

.active {
  background-color: #EFF4FB;
}

.active svg g {
  opacity: 1
}

.active svg path {
  stroke: #005EB8;
}
