@import '../../../../../styles/variables';

.moreFiltersContainer {
  padding: 20px;

  @media (max-width: 1024px) {
    padding: 0;
  }
}

.header {
  padding-bottom: 16px;
  border-bottom: 1.5px solid $blueShades96;
  font-weight: 600;
}

.moreFiltersTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.01em;
  padding-bottom: 25px;
}

.moreFiltersSubTitle {
  font-weight: 600;
  line-height: 24px;
}

.moreFiltersButton {
  height: 40px;
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
}

.moreFiltersCategories {
  margin: 15px 0;
}

.break {
  margin: 30px 0;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.price {
  height: 100px;
  position: relative;
}

.priceRange {
  position: absolute;
  bottom: 18px;
}

.priceValue {
  font-weight: 600;
  line-height: 26.1px;
  letter-spacing: -0.005em;
}

.featuresInput {
  width: 96px;
}

.features {
  margin: 10px 0;
}

.period {
  margin-top: 10px;
}

.periodOption {
  margin: 5px 0;
}

.footer {
  padding: 20px;
}

.footerButtons {
  width: 155px;
  height: 56px;
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  border: 2px 0px 0px 0px;
  margin: 5px;
}

.section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;

  &:last-child {
    border-bottom: none;
    margin-bottom: 10px;
    padding-bottom: 0;
  }
}

.sectionTitle {
  margin-bottom: 18px!important;
  font-weight: 500!important;;
  text-align: left!important;;
}

.roomsSelect {
  > div {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}