.card {
  margin: 30px 0;
}

.title {
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.01em;
  padding-bottom: 20px;
}

.break {
  margin: 30px 0;
  width: 100%;
}

:global(.ant-table-wrapper .ant-table-pagination-right) {
  @media (max-width: 992px) {
    justify-content: center;
  }
}

:global(.ant-table-wrapper) {
  @media (max-width: 992px) {
    overflow-x: auto;
  }
}

// :global(.ant-table-cell) {
//   @media (max-width: 992px) {
//     padding: 40px 50px 40px 24px !important;
//   }
// }
