.specifications {
  height: 115px;
  border-radius: 8px;
  border: 1.5px solid #eff4fb;
  margin-bottom: 20px;
  padding: 20px;

  @media (max-width: 992px) {
    height: auto;
  }
}

.outer {
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.specifications .specTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
  padding-bottom: 15px;
}

.specifications .specValue {
  font-weight: 600;
  line-height: 26.1px;
  letter-spacing: -0.005em;
  div {
    span {
      svg {
        width: 24px;
        height: 24px;
        g {
          path {
            stroke: black !important;
            opacity: 0.5;
          }
        }
        path {
          stroke: black !important;
          opacity: 0.5;
        }
      }
    }
  }
}
