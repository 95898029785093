.marketWatch {
  align-items: center;
}

.cardTitle {
  font-weight: 700;
  line-height: 31.25px;
  letter-spacing: -0.01em;
}

.marketWatchSettings {
  text-align: right;
  margin-left: auto;
  @media (max-width: 1024px) {
    text-align: left;
    display: flex;
  }
}

.marketWatchSettingsMore {
  position: absolute;
  right: 0;
  top: 0;
}

.marketWatchGrid {
  display: flex;
  justify-content: space-between; /* Ensure equal spacing */
  align-items: center; /* Center items vertically */
  width: 100%;
  margin-top: 24px;
  box-shadow: none;
  border-radius: 8px;
  background-color: #f5f5f5;

  @media (max-width: 1024px) {
    flex-direction: column; /* Stack items vertically on mobile */
    margin-top: 17px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.1);
  }
}

.marketWatchGridItem {
  display: flex;
  flex-direction: column; /* Stack the number and label vertically */
  align-items: center;
  justify-content: center;
  flex: 1; /* Make each item take up equal space */
  padding: 10px 20px;
  border-right: 1px solid #ccc; /* Divider bar between items */
  margin: 0 8px; /* Add some horizontal spacing */

  &:last-child {
    border-right: none; /* Remove the last divider */
  }

  @media (max-width: 1024px) {
    flex: 1 1 100%;
    border-right: none;
    border-bottom: 1px solid #ccc; /* Divider bar for stacked items */
    margin: 8px 0; /* Add vertical spacing for mobile */

    &:last-child {
      border-bottom: none; /* Remove the last divider for mobile */
    }
  }
}

.marketWatchIcon {
  text-align: left;
}

.marketWatchValue {
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  margin-bottom: 5px; /* Space between value and description */
}

.marketWatchValueDesc {
  font-weight: 500;
  line-height: 19.6px;
}

.marketWatchInputContainer {
  margin-top: 20px;
  :global(.ant-col) {
    @media (max-width: 1024px) {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.propertiesLink {
  padding-top: 16px;
  font-size: 16px;
  a {
    font-weight: 700;
  }
}

.avatar {
  background-color: #DEE8F7;
}
