.listingItem {
  position: relative;
  gap: 0px;
  border: 1.5px solid #EFF4FB;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  height: 40%;
  display: flex;
}

.popularBanner {
  position: absolute;
  top: 107px;
  left: -9px;
}

.listingContent {
  padding: 20px 20px 10px 20px;
}

.listingPrice {
  display: flex;
  justify-content: space-between;
  min-height: 32px;
}

.price {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  text-align: left;
}

.month {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  opacity: .5;
}

.county {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cityState {
  font-weight: 500;
  line-height: 16.2px;
  text-align: left;
  opacity: .5;
}

.break {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;

  &.large {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.listingPropertiesContainer {
  padding:5% 10% 5% 10%;

  &.large {
    padding: 5%;
  }
}

.listingProperties {
  display: inline-block;
  font-weight: 500;
  text-align: left; 
}

.listingProperties .value {
  opacity: .7;
  margin-left: 8px;
  font-size: 14px;
}

.listingProperties .sqft {  
  opacity: .7;
  margin-left: 5px;
  font-size: 12px;
}

