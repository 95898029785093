.drawerTitle {
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: left;
}

.drawerSubTitle {
  line-height: 25.6px;
  text-align: left;
}

.filtersButton:hover svg path{
  fill: white;
  stroke: #005EB8;
}

.titleContainer {
  margin: 20px 0 20px 0;
}

.filtersContainer {
  margin: 20px 0 20px 0;
}

.filter {
  height: 48px;
}

.contentContainer {
  margin: 20px 0 20px 0;
}

.drawerOptionsContainer {
  display: flex;
  justify-content: flex-end;
}
