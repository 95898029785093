.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  padding-bottom: 8px;
}

.formHead {
    margin-bottom: 20px;
}

.formInput {
    margin-bottom: 16px;

    :global(.ant-col) {
        @media (max-width: 1024px) {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.input,
.select {
    height: 48px;
}

.formFooter {
  margin-top: 20px;
}

.searchTitle {
  font-weight: 700;
  line-height: 37.5px;
  letter-spacing: -0.01em;
}
